import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import {
  getCountry,
  getState,
  getCity,
  get_country,
  get_state,
  get_city,
  getCustomerTypeAll,
  getCustomerType,
  getInternalCompany,
  get_GST_details,
  get_gst_treatment,
  saveCustomerType,
} from "../../../../../api/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { getLabels } from "../../../../../common/Functions/CommonFunctions";
import { Spinner } from "react-bootstrap";
import AddOrganization from "../../../AddMasters/AddOrganization";

const schema = yup
  .object()
  .shape({
    customerType: yup
      .array()
      .min(1, "Please select customer type !")
      .required("Please select customer type !"),
    org: yup
      .array()
      .min(1, "Please select organization !")
      .required("Please select organization !"),
    organization: yup.string().required("Company name is required !"),
    address: yup.string().required("Address is required !"),
    cState: yup
      .array()
      .min(1, "Please select state !")
      .required("Please select state !"),
    cCountry: yup
      .array()
      .min(1, "Please select country !")
      .required("Please select country !"),
    cCity: yup
      .array()
      .min(1, "Please select city !")
      .required("Please select city !"),
    email: yup.string().email("Enter valid email id").notRequired(),
    primaryContactNo: yup
      .string()
      .matches(/^[0-9]*$/, "Enter valid contact number")
      .notRequired(),
    secondaryContactNo: yup
      .string()
      .matches(/^[0-9]*$/, "Enter valid alternate contact number")
      .notRequired(),
    postalCode: yup
      .string()
      .required("Postal code is required !")
      .matches(/^[0-9]*$/, "Enter valid postal code"),
    gstTreat: yup
      .array()
      .min(1, "Please select gst registration type !")
      .required("Please select gst registration type !"),
    gst: yup.string().when(["gstTreat"], (gstTreat, schema) => {
        if (gstTreat !== undefined && gstTreat.length > 0) {
          const gst_typeSelected = gstTreat[0].name == "Regular";
  
          // If 'gst_tye' is in the category, make 'GST number' mandatory
          return gst_typeSelected
            ? schema
                .required("Please enter GST number !")
                .max(15, "GST number must be 15 characters only !")
                .matches(
                  /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1})$/,
                  "Enter valid GST number!"
                )
            : schema;
        } else {
          return schema;
        }
      }),
  
    PANNO: yup.string().when(["gstTreat"], (gstTreat, schema) => {
        if (gstTreat !== undefined && gstTreat.length > 0) {
          const gst_typeSelected = gstTreat[0].name == "Regular";
  
          // If 'gst_tye' is in the category, make 'GST number' mandatory
          return gst_typeSelected
            ? schema
              .required("Please enter PAN number !")
              .min(10, "PAN number must be 10 characters only !")
              .max(10, "PAN number must be 10 characters only !")
              .matches(
                /^([A-Z]{5}[0-9]{4}[A-Z]{1})$/,
                "Enter a valid PAN number!"
              )
            : schema;
        } else {
          return schema;
        }
      }),
    // check_gst: yup
    //   .string()
    //   .matches(
    //     /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1})$/,
    //     "Enter valid GST number!"
    //   ),
  })
  .required();

function NewCreateBasicDetail(props) {
  let location = useLocation();
  const [countryList, setCountryList] = useState({ data: [], state: false });
  const [stateList, setStateList] = useState({ data: [], state: false });
  const [cityList, setCityList] = useState({ data: [], state: false });
  const [custType, setCustType] = useState({ data: [], state: false });
  const [internalcompany, setInternalcompany] = useState({
    data: [],
    state: false,
  });
  const [gst_treat, setGST_Treat] = useState({ loading: false, data: [] });

  const [lock, setLock] = useState(false);
  const [gstin, setGSTin] = useState("");
  const [t_state, setT_State] = useState("");
  const [t_city, setT_City] = useState("");

  var FormData = require("form-data");
  const { register, handleSubmit, formState, control, reset, watch, setValue, setError, clearErrors } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    }); // initialise the hook
  let navigate = useNavigate();
  const selectedCountry = watch("cCountry");
  const selectedState = watch("cState");

  const [constLabel, setConstLabel] = useState({});

  let { errors } = formState;
  let gstType = watch("gstTreat");

  useEffect(() => {
    setConstLabel(getLabels("Party Master", "Customer"));
    // resetting the form
    fetchCustomerType();
    fetchInternal_company();
    fetchGSTTreatment();
  }, []);

  useEffect(() => {
    // resetting the form
    // reset();
    setCountryList({ ...countryList, state: true, data: [] });

    //axios.post("https://masters.ravicam.in/api/master/country/",{payload}).then(
    get_country().then(
      (res) => {
        setCountryList({
          ...countryList,
          state: false,
          data: res.data.data,
        });

        if(location && location?.pathname !== '/pcms/neweditCustomer'){
          let result = res.data.data.filter((item) => item.countryName === "India");
          setValue("cCountry", result);
        }
      },
      (err) => {
        setCountryList({ ...countryList, state: false, data: [] });
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
      }
    );
  }, []);

  useEffect(() => {
    // fetching states for selected country through api
    if (selectedCountry !== undefined && selectedCountry.length !== 0) {
      setStateList({ ...stateList, data: [], state: true });

      const payload = {
        country_id: selectedCountry[0]?.uuid,
      };
      get_state(payload).then(
        (res) => {
          setStateList({
            ...stateList,
            data: res.data.data,
            state: false,
          });
        },
        (err) => {
          setStateList({ ...stateList, data: [], state: false });
          // toast.error("Something went wrong in fetching states !", { autoClose: 5000 })
        }
      );
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState !== undefined && selectedState.length > 0) {
      setCityList({ ...cityList, data: [], state: true });

      const payload = {
        state_id: selectedState[0]?.uuid || t_state,
      };

      get_city(payload).then(
        (res) => {

          if(t_city !== ""){
            let result = res.data.data.filter((item) => item.cityName === t_city);
            setValue("cCity", result);
          }
          
          setCityList({ ...cityList, data: res.data.data, state: false });
        },
        (err) => {
          setCityList({ ...cityList, data: [], state: false });
          // toast.error("Something went wrong in fetching cities !", { autoClose: 5000 })
        }
      );
    }
  }, [selectedState]);

  const onSubmit = (data) => {
    if (data !== "") {
      const basic = {
        partyType: "Customer",
        customerType: data.customerType[0].typename,
        customer_type_id: data.customerType[0].uuid,
        organization: data.organization,
        org_id: data.org[0].uuid,
        website: data.website,
        name: data.name,
        primaryContactNo: data.primaryContactNo,
        secondaryContactNo: data.secondaryContactNo,
        email: data.email,
        country: (data?.cCountry[0]?.id).toString(),
        state: (data.cState[0]?.id).toString(),
        city: (data.cCity[0]?.id).toString(),
        country_name: data.cCountry[0]?.countryName,
        state_name: data.cState[0]?.stateName,
        city_name: data.cCity[0]?.cityName,
        country_id: data.cCountry[0]?.uuid,
        state_id: data.cState[0]?.uuid,
        city_id: data.cCity[0]?.uuid,
        address: data.address,
        postalCode: data.postalCode,
        // vendor_code: data.vendor_code,
        createdBy: 1,
        modifiedBy: 1,

        PANNO: data.PANNO ?? null,
        gst: data.gst,
        gst_treatment: data.gstTreat[0]?.name,
      };

      // localStorage.setItem("basic", JSON.stringify(basic));
      props.setMain_obj({ ...props.main_obj, ...basic });
      props.nextStep();
    } else {
      errors.showMessages();
    }
  };

  const fetchInternal_company = () => {
    setInternalcompany({
      data: [],
      state: true,
    });
    // const payload = {
    //   pageNo: 1,
    //   entriesPerPage: 100,
    // };
    getInternalCompany()
      .then((res) => {

        if(location && location?.pathname !== '/pcms/neweditCustomer'){
          const ress = res.data.data.filter((item) => item.default_org == true);
          setValue("org", ress);
        }
        

        setInternalcompany({
          data: res.data.data,
          state: false,
        });

        if (props.main_obj.org_id && props.main_obj.org_id !== null) {
          if (typeof props.main_obj.org_id == "string") {
            let o = res.data.data.filter(
              (comp) => comp.uuid == props.main_obj.org_id
            );
            setValue("org", o);
          } else {
            let o = res.data.data.filter(
              (comp) => comp.uuid == props.main_obj.org_id.uuid
            );
            setValue("org", o);
          }
        }
      })
      .catch((err) => {
        setInternalcompany({
          data: [],
          state: false,
        });
      });
  };

  

  function validateGST(value){
      const gstRegex = /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1})$/;
      if (gstRegex.test(value)) {
        clearErrors("check_gst");
        return true;
      } else {
        return false;
      }
  }

  async function fetchGSTDetails() {
    setLock(true);
    if (!validateGST(gstin)) {
      setError('check_gst', {
        type:"manual",
        message:"Please enter a valid GST number"
      })
      // setGSTin("");
      setLock(false);
      return;
    }
    clearErrors("check_gst");

   await get_GST_details({gstin: gstin}).then(
      (res) => {
        console.log("res", res);
        const obj = {
          organization: res.data.data.data.lgnm || "",
          postalCode: res.data.data.data.pradr.addr.pncd || "",
          address: res.data.data.data.adadr.length > 0 ? (res.data.data.data.adadr[0].addr.bno +" "+ res.data.data.data.adadr[0].addr.bnm +" "+ res.data.data.data.adadr[0].addr.st +" "+ res.data.data.data.adadr[0].addr.loc) : (res.data.data.data.pradr?.addr?.bno +" "+ res.data.data.data.pradr?.addr?.bnm +" "+ res.data.data.data.pradr?.addr?.st +" "+ res.data.data.data.adadr?.addr?.loc),
          // gst: res.data.data.data.gstin,
          // gst_treatment: res.data.data.data.dty,
          // stateName: res.data.data.pradr.addr.stcd,
          // cityName: res.data.data.pradr.addr.dst
        }

        setValue("organization", res.data.data.data.lgnm || "", {shouldValidate: true});
        if(res.data.data.data.adadr.length > 0){
          setValue("address", res.data.data.data.adadr[0].addr.bno +" "+ res.data.data.data.adadr[0].addr.bnm +" "+ res.data.data.data.adadr[0].addr.st +" "+ res.data.data.data.adadr[0].addr.loc || "", {shouldValidate: true});
        } else {
          setValue("address", res.data.data.data.pradr?.addr?.bno +" "+ res.data.data.data.pradr?.addr?.bnm +" "+ res.data.data.data.pradr?.addr?.st +" "+ res.data.data.data.adadr?.addr?.loc || "", {shouldValidate: true});
        }
        setValue("postalCode", res.data.data.data.pradr.addr.pncd || "", {shouldValidate: true});
        setValue("gstTreat", [{name: res.data.data.data.dty}] || [{name: "Regular"}], {shouldValidate: true});
        setValue("gst", res.data.data.data.gstin || "", {shouldValidate: true});


        if(stateList.data && stateList.data.length > 0){
          let st = stateList.data.filter((item) => item.stateName === res.data.data.data.pradr.addr.stcd);
          setValue("cState", st, {shouldValidate: true});
          setT_State(st[0]?.uuid);
          obj.state = (st[0]?.id).toString();
          obj.state_name = st[0]?.stateName;
          obj.state_id = st[0]?.uuid;

          setT_City(res.data.data.data.pradr.addr.dst);
        }
        props.setMain_obj({...props.main_obj, ...obj});
        setLock(false);
      },
      (err) => {
        setLock(false);
      }
    );
  }

  function fetchGSTTreatment() {
    setGST_Treat({ ...gst_treat, state: true, data: [] });

    get_gst_treatment().then(
      (res) => {

        setGST_Treat({
          ...gst_treat,
          state: false,
          data: res.data.data,
        });
      },
      (err) => {
        //
        setGST_Treat({ ...gst_treat, state: false, data: [] });
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
      }
    );
  }

  function fetchCustomerType(){
    setCustType({ ...custType, state: true, data: [] });
    getCustomerType().then(
      (res) => {
        setCustType({
          ...custType,
          state: false,
          data: res.data.data,
        });
      },
      (err) => {
        setCustType({ ...custType, state: false, data: [] });
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
      }
    );
  }

  function addCustomerType(data){

    if (data !== "") {
      const reqPayload = {
        typename: data,
      };
      saveCustomerType(reqPayload).then(
        (res) => {
          console.log("res", res);
          if (res.status === 200 || res.status === 201) {
            toast.success("Customer type created successfully !", {
              autoClose: 2000,
            });
            setTimeout(() => {
              fetchCustomerType();
            }, 2300);
            
            setValue("customerType", [res.data], { shouldValidate: true });
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            reset();
          }
        }
      );
    } else {
      errors.showMessages();
    }
  }


  return (
    <>
      <div className="row">
        <h4 className="mb-5 roboto-bold">
          {constLabel?.lbl_company_detail
            ? constLabel?.lbl_company_detail
            : "Company Detail"}
        </h4>
      </div>

    {location.state !== null && location.pathname == "/pcms/neweditCustomer" ? null :
      <div className="row">
        <div className="col-12 col-md-6 row me-0 me-md-1">
          <label
            style={{ fontSize: "14px" }}
            className="col-form-label roboto-bold col-12 col-md-3"
          >
            {constLabel?.lbl_gst_no ? constLabel?.lbl_gst_no : "GST No" }
          </label>

          <div className="col-12 col-md-9">
            <input
              className={
                errors.check_gst
                  ? "form-control is-invalid"
                  : "form-control"
              }
              type="text"
              name="check_gst"
              placeholder={"Enter gst no"}
              onChange={(e) => setGSTin(e.target.value)}
              value={gstin}
            />
            <span className="text-danger err-msg">
              {errors.check_gst?.message}
            </span>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <button
            className="btn btn-grey px-3"
            onClick={() => fetchGSTDetails()}
            disabled={gstin.length == 0 ? true : false}
          >
            {constLabel?.lbl_search ? constLabel.lbl_search : "Search"}{" "}
            {lock ? <Spinner animation="border" size="sm" /> : null}
          </button>
        </div>
      </div>
    }

      <hr className="my-4" />

      <div className="row">
        <div className="col-12 col-md-3"> 
          <div className="form-group mt-md-0 mt-3 typeahead-container">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label roboto-bold"
            >
              {constLabel?.lbl_customer_type
                ? constLabel.lbl_customer_type
                : "Customer Type"}
              <span>
                <i style={{ color: "red" }}>*</i>
              </span>
            </label>

            <Controller
              name="customerType"
              control={control}
              defaultValue={
                props.main_obj.customerType && [
                  {
                    typename: props.main_obj.customerType,
                    uuid: props.main_obj.customer_type_id,
                  },
                ]
              }
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="typename"
                  multiple={false}
                  // size="sm"
                  options={custType.data !== undefined ? custType.data : []}
                  isLoading={custType.state}
                  placeholder="Select customer type"
                  // onChange={onChange}
                  onChange={(selected) => {
                    // If the new option is added
                    const isNewOption = selected && selected[0]?.customOption;
                    if (isNewOption) {
                      const newItem = selected[0].typename;
                      // Fire API to add the new item
                      addCustomerType(newItem);
                    }
                    onChange(selected);
                  }}
                  newSelectionPrefix="Add New: "
                  allowNew                // Enables adding new options
                  onBlur={onBlur}
                  selected={value}
                  defaultSelected={
                    props.main_obj.customerType && [
                      {
                        typename: props.main_obj.customerType,
                        uuid: props.main_obj.customer_type_id,
                      },
                    ]
                  }
                  isInvalid={errors.customerType ? true : false}
                />
              )}
            />
            {!custType.state && !errors.customerType && <span className="arrow-icon"></span>}
            <span className="text-danger err-msg">
              {errors.customerType?.message}
            </span>
          </div>
        </div>

        <div className="col-12 col-md-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label col-md-12 roboto-bold d-flex align-items-center" 
            >
              {constLabel?.lbl_company_name
                ? constLabel?.lbl_company_name
                : "Company Name"}
              <span className="text-danger"> * </span>
            </label>
            <input
              type="text"
              className={
                errors.organization
                  ? "form-control   is-invalid"
                  : "form-control  "
              }
              id="organization"
              name="organization"
              placeholder="Enter company name"
              defaultValue={
                props.main_obj.organization && props.main_obj.organization
              }
              {...register("organization")}
            />
            <span className="text-danger err-msg">
              {errors.organization?.message}
            </span>
          </div>
        </div>

        <div className="col-12 col-md-3">
          <div className="form-group mt-md-0 mt-3 typeahead-container">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label col-md-12 roboto-bold d-flex align-items-center"
            >
              {constLabel?.lbl_organization
                ? constLabel?.lbl_organization
                : "Organization"}
              <span className="text-danger"> * </span>
              <AddOrganization fetchInternal_company={fetchInternal_company} />
            </label>

            <Controller
              name="org"
              control={control}
              //  defaultValue={props.main_obj.customerType && [{ typename: props.main_obj.customerType }]}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="comName"
                  multiple={false}
                  // size="sm"
                  options={
                    internalcompany.data.length > 0 ? internalcompany.data : []
                  }
                  isLoading={internalcompany.state}
                  placeholder="Select organization"
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  // defaultSelected={props.main_obj.org && [{ typename: props.main_obj.customerType }]}
                  isInvalid={errors.org ? true : false}
                />
              )}
            />
            {!internalcompany.state && !errors.org && <span className="arrow-icon"></span>}
            <span className="text-danger err-msg">{errors.org?.message}</span>
          </div>
        </div>

        <div className="col-12 col-md-3">
          <div className="form-group mt-md-0 mt-3 typeahead-container">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label col-md-12 roboto-bold"
            >
              {constLabel?.lbl_gst_treatment
                ? constLabel?.lbl_gst_treatment
                : "GST Registration Type"}
              <span className="text-danger"> * </span>
            </label>

            <Controller
              name="gstTreat"
              control={control}
              defaultValue={
                props.main_obj.gst_treatment !== undefined && props.main_obj.gst_treatment?.length > 0
                  ? [{ name: props.main_obj.gst_treatment }]
                  : [{ name: "Regular" }]
              }
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="name"
                  multiple={false}
                  options={gst_treat.data !== undefined ? gst_treat.data : []}
                  isLoading={gst_treat.state}
                  placeholder="Select gst registration type..."
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  defaultSelected={
                    props.main_obj?.gst_treatment !== undefined && props.main_obj?.gst_treatment?.length > 0
                      ? [{ name: props.main_obj.gst_treatment }]
                      : [{ name: "Regular" }]
                  }
                  isInvalid={errors.gstTreat ? true : false}
                />
              )}
            />
            {!gst_treat.state && !errors.gstTreat && <span className="arrow-icon"></span>}
            <span className="text-danger err-msg">{errors.gstTreat?.message}</span>
          </div>
        </div>

        <div className="col-12 col-md-3 mt-3">
          <div className="form-group mt-md-0 mt-3">
          <label
              style={{ fontSize: "14px" }}
              className="col-form-control roboto-bold"
            >
              {constLabel?.lbl_gst_no ? constLabel.lbl_gst_no : "GST No"}
              { gstType && gstType?.[0]?.name == "Regular" ? <span className="text-danger" > *</span> : null }
            </label>
              <input
                type="text"
                className={
                  errors.gst && (gstType && gstType?.[0]?.name == "Regular")
                    ? "form-control is-invalid mt-2"
                    : "form-control mt-2"
                }
                id="gst"
                maxLength={15}
                name="gst"
                defaultValue={
                  props.main_obj.gst !== undefined && props.main_obj.gst && props.main_obj.gst?.length > 0 ? props.main_obj.gst : ""
                }
                placeholder="Enter GST number"
                {...register("gst")}
              />
              {errors.gst && gstType && gstType?.[0]?.name == "Regular" ? 
                <span className="text-danger err-msg">
                  {errors.gst?.message}
                </span> : null
              }
          </div>
        </div>

        <div className="col-12 col-md-3 mt-3">
          <div className="form-group mt-md-0 mt-3">
          <label
              style={{ fontSize: "14px" }}
              className="col-form-control roboto-bold"
            >
              {constLabel?.lbl_pan ? constLabel.lbl_pan : "PAN"}
              { gstType && gstType?.[0]?.name == "Regular" ? <span className="text-danger" > *</span> : null }
            </label>
              <input
                type="text"
                className={
                  errors.PANNO && (gstType && gstType?.[0]?.name == "Regular")
                    ? "form-control is-invalid mt-2"
                    : "form-control mt-2"
                }
                id="PANNO"
                maxLength={10}
                name="PANNO"
                placeholder="Enter PAN number"
                defaultValue={
                  props.main_obj.PANNO !== undefined && props.main_obj.PANNO !== null && props.main_obj.PANNO?.length > 0
                    ? props.main_obj.PANNO
                    : ""
                }
                {...register("PANNO")}
              />
              {errors.PANNO && gstType && gstType?.[0]?.name == "Regular" ? 
                <span className="text-danger err-msg">
                  {errors.PANNO?.message}
                </span> : null
              }
          </div>
        </div>

      </div>

      <hr className="my-4" />

      <div className="row">
        <div className="col-12 col-md-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label roboto-bold"
            >
              {constLabel?.lbl_name ? constLabel?.lbl_name : "Name"}
            </label>

            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              placeholder="Enter full name"
              defaultValue={
                props.main_obj.name &&
                (props.main_obj.name !== null || props.main_obj !== undefined)
                  ? props.main_obj.name
                  : ""
              }
              {...register("name")}
            />
          </div>
        </div>

        <div className="col-12 col-md-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label roboto-bold"
            >
              {constLabel?.lbl_contact_no
                ? constLabel.lbl_contact_no
                : "Contact No"}
            </label>

            <input
              type="text"
              className="form-control"
              id="primaryContactNo"
              name="primaryContactNo"
              placeholder="Enter contact no"
              maxLength={10}
              defaultValue={
                props.main_obj.primaryContactNo &&
                props.main_obj.primaryContactNo
              }
              {...register("primaryContactNo")}
            />
            <span className="text-danger err-msg">
              {errors.primaryContactNo?.message}
            </span>
          </div>
        </div>

        <div className="col-12 col-md-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label roboto-bold"
            >
              {constLabel?.lbl_alternate_contact
                ? constLabel?.lbl_alternate_contact
                : "Alternate Contact"}
            </label>
            <input
              type="text"
              className="form-control "
              id="secondaryContactNo"
              name="secondaryContactNo"
              maxLength={10}
              placeholder="Enter alternate contact"
              defaultValue={
                props.main_obj.secondaryContactNo &&
                (props.main_obj.secondaryContactNo !== null ||
                  props.main_obj.secondaryContactNo !== undefined)
                  ? props.main_obj.secondaryContactNo
                  : ""
              }
              {...register("secondaryContactNo")}
            />
            <span className="text-danger err-msg">
              {errors.secondaryContactNo?.message}
            </span>
          </div>
        </div>

        <div className="col-12 col-md-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label roboto-bold"
            >
              {constLabel?.lbl_email_id ? constLabel.lbl_email_id : "Email Id"}
            </label>

            <input
              type="email"
              className="form-control "
              id="email"
              name="email"
              placeholder="Enter email id"
              defaultValue={
                props.main_obj.email &&
                (props.main_obj.email !== null ||
                  props.main_obj.email !== undefined)
                  ? props.main_obj.email
                  : ""
              }
              {...register("email")}
            />
            <span className="text-danger err-msg">{errors.email?.message}</span>
          </div>
        </div>

        <div className="col-12 col-md-3 mt-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label roboto-bold"
            >
              {constLabel?.lbl_website ? constLabel.lbl_website : "Website"}
            </label>

            <input
              type="text"
              className="form-control "
              id="website"
              name="website"
              placeholder="Enter website"
              defaultValue={props.main_obj.website && props.main_obj.website}
              {...register("website")}
            />
          </div>
        </div>

        <div className="col-12 col-md-3 mt-3">
          <div className="form-group mt-md-0 mt-3 typeahead-container">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label roboto-bold"
            >
              {constLabel?.lbl_country ? constLabel.lbl_country : "Country"}
              <span>
                <i style={{ color: "red" }}>*</i>
              </span>
            </label>

            <Controller
              name="cCountry"
              control={control}
              defaultValue={
                props.main_obj.country &&
                (props.main_obj.country_name !== null ||
                  props.main_obj.country_id !== null)
                  ? [
                      {
                        id: props.main_obj.country,
                        countryName: props.main_obj.country_name,
                        uuid: props.main_obj.country_id,
                      },
                    ]
                  : []
              }
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="countryName"
                  multiple={false}
                  // size="sm"
                  options={
                    countryList.data !== undefined ? countryList.data : []
                  }
                  isLoading={countryList.state}
                  placeholder="Select country"
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  defaultSelected={
                    props.main_obj.country &&
                    (props.main_obj.country_name !== null ||
                      props.main_obj.country_id !== null)
                      ? [
                          {
                            id: props.main_obj.country,
                            countryName: props.main_obj.country_name,
                            uuid: props.main_obj.country_id,
                          },
                        ]
                      : []
                  }
                  isInvalid={errors.cCountry ? true : false}
                />
              )}
            />
            {!countryList.state && !errors.cCountry && <span className="arrow-icon"></span>}
            <span className="text-danger err-msg">
              {errors.cCountry?.message}
            </span>
          </div>
        </div>

        <div className="col-12 col-md-3 mt-3">
          <div className="form-group mt-md-0 mt-3 typeahead-container">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label roboto-bold"
            >
              {constLabel?.lbl_state ? constLabel.lbl_state : "State"}
              <span>
                <i style={{ color: "red" }}>*</i>
              </span>
            </label>

            <Controller
              name="cState"
              control={control}
              defaultValue={
                props.main_obj.state &&
                (props.main_obj.state_name !== null ||
                  props.main_obj.state_id !== null)
                  ? [
                      {
                        id: props.main_obj.state,
                        stateName: props.main_obj.state_name,
                        uuid: props.main_obj.state_id,
                      },
                    ]
                  : []
              }
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="stateName"
                  // size="sm"
                  multiple={false}
                  options={stateList.data !== undefined ? stateList.data : []}
                  isLoading={stateList.state}
                  placeholder="Select state"
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  defaultSelected={
                    props.main_obj.state &&
                    (props.main_obj.state_name !== null ||
                      props.main_obj.state_id !== null)
                      ? [
                          {
                            id: props.main_obj.state,
                            stateName: props.main_obj.state_name,
                            uuid: props.main_obj.state_id,
                          },
                        ]
                      : []
                  }
                  isInvalid={errors.cState ? true : false}
                />
              )}
            />
            {!stateList.state && !errors.cState && <span className="arrow-icon"></span>}
            <span className="text-danger err-msg">
              {errors.cState?.message}
            </span>
          </div>
        </div>

        <div className="col-12 col-md-3 mt-3">
          <div className="form-group mt-md-0 mt-3 typeahead-container">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label col-md-12 roboto-bold"
            >
              {constLabel?.lbl_city ? constLabel.lbl_city : "City"}
              <span>
                <i style={{ color: "red" }}>*</i>
              </span>
            </label>
            <Controller
              name="cCity"
              control={control}
              defaultValue={
                props.main_obj.city &&
                (props.main_obj.city_name !== null ||
                  props.main_obj.city_id !== null)
                  ? [
                      {
                        id: props.main_obj.city,
                        cityName: props.main_obj.city_name,
                        uuid: props.main_obj.city_id,
                      },
                    ]
                  : []
              }
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="cityName"
                  // size="sm"
                  multiple={false}
                  options={cityList.data !== undefined ? cityList.data : []}
                  isLoading={cityList.state}
                  placeholder="Select city"
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  defaultSelected={
                    props.main_obj.city &&
                    ((props.main_obj.city_name !== "" && props.main_obj.city_name !== null) ||
                    (props.main_obj.city_id !== "" && props.main_obj.city_id !== null))
                      ? [
                          {
                            id: props.main_obj.city,
                            cityName: props.main_obj.city_name,
                            uuid: props.main_obj.city_id,
                          },
                        ]
                      : []
                  }
                  isInvalid={errors.cCity ? true : false}
                />
              )}
            />
            {!cityList.state && !errors.cCity && <span className="arrow-icon"></span>}
            <span className="text-danger err-msg">{errors.cCity?.message}</span>
          </div>
        </div>

        <div className="col-12 col-md-3 mt-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label roboto-bold"
            >
              {constLabel?.lbl_postal_code
                ? constLabel.lbl_postal_code
                : "Postal Code"}
              <span>
                <i style={{ color: "red" }}>*</i>
              </span>
            </label>
            <input
              type="text"
              className="form-control "
              id="postalCode"
              name="postalCode"
              placeholder="Enter postal code"
              {...register("postalCode")}
              defaultValue={
                props.main_obj.postalCode && props.main_obj.postalCode
              }
              maxLength={6}
            />
            <span className="text-danger err-msg">
              {errors.postalCode?.message}
            </span>
          </div>
        </div>

        <div className="col-12 col-md-3 mt-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label roboto-bold"
            >
              {constLabel?.lbl_address ? constLabel.lbl_address : "Address"}
              <span>
                <i style={{ color: "red" }}>*</i>
              </span>
            </label>
            <textarea
              type="text"
              className={
                errors.address ? "form-control  is-invalid" : "form-control "
              }
              id="address"
              name="address"
              placeholder="Enter address"
              defaultValue={props.main_obj.address && props.main_obj.address}
              {...register("address")}
            ></textarea>
            <span className="text-danger err-msg">
              {errors.address?.message}
            </span>
          </div>
        </div>
      </div>
      <hr className="my-3" />
      <div className="row  mt-0 ">
        <div className="d-md-flex justify-content-end">
          {/* <button
            onClick={() => {
              navigate(`/pcms/customer`);
            }}
            className="btn btn-grey px-4 me-2"
          >
            {"Cancel "}
          </button> */}
          <button
            className="btn button-primary px-4 me-2"
            onClick={handleSubmit(onSubmit)}
          >
            {constLabel?.lbl_save_continue
              ? constLabel?.lbl_save_continue
              : "Save & Continue"}
          </button>
          <button
            className="btn btn-grey px-4 me-2"
            disabled={Object.keys(props.main_obj).length > 11  ? false : true}
            onClick={() => props.nextStep()}
          >
            {constLabel?.lbl_skip ? constLabel?.lbl_skip : "Skip"}
          </button>
        </div>
      </div>
    </>
  );
}

export default NewCreateBasicDetail;
