import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faClose } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// import { get_document_type } from "../../../../../api/index";
// import { Typeahead } from "react-bootstrap-typeahead";
import { getLabels } from "../../common/Functions/CommonFunctions";
import audio from "../../assets/images/audio.png"

const imageFileTypes = ["image/jpeg", "image/jpg", "image/png"];
const audioFileTypes = ["audio/mpeg", "audio/wav", "audio/mp3"];

const schema = yup
  .object()
  .shape({
    image: yup
      .mixed()
      .test(
        "fileSize",
        "File can be uploaded up to 10 MB",
        (value) => !value || (value && value[0]?.size <= 10 * 1024 * 1024)
      )
      .test("fileType", "Only image or audio files are allowed", (value) => {
        if (!value) return true; // If no file is selected, it's considered valid.
        const fileType = value[0]?.type;
        return imageFileTypes.includes(fileType) || audioFileTypes.includes(fileType);
      })
      .required("File is required"),
  })
  .required();

function SOImageGallary(props) {
  // const [docTypeList, setDocTypeList] = useState({ data: [], loading: false });

  const [constLabel, setConstLabel] = useState({});

  const {
    register,
    handleSubmit,
    formState,
    // control,
    reset,
    // watch,
    // setValue,
    // setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  let { errors } = formState;

  useEffect(() => {
    setConstLabel(getLabels("Sales", "Sales Order"));
  }, []);

  const onsubmit = (data) => {
    let tempImg = [...props.drawingFiles];
    tempImg.push({
      // filename: data.fileName,
      file: data.image[0],
    });
    // console.log("tempImg", tempImg);
    props.setDrawingFiles(tempImg);

    reset({
      image: null,
      // fileName: "",
    });
  };

  function removeFile(i) {
    let tempImg = [...props.drawingFiles];

    tempImg.splice(i, 1);
    props.setDrawingFiles(tempImg);
  }

  return (
    <React.Fragment>
      <Modal
        show={props.galVisible}
        onHide={() => props.setGalVisible(false)}
        size="lg"
        backdrop="static"
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {constLabel?.lbl_image_gallary
              ? constLabel.lbl_image_gallary
              : "Image Gallery"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 col-md-4">
              <label style={{ fontSize: "14px" }} className="col-form-label">
                {constLabel?.lbl_file ? constLabel.lbl_file : "File"}
              </label>
              <input
                className={
                  errors.image
                    ? "form-control form-control-sm is-invalid"
                    : "form-control form-control-sm"
                }
                type="file"
                {...register("image")}
                accept="image/*, audio/*"
              />
              {errors.image && (
                <span className="text-danger err-msg">
                  {errors.image.message}
                </span>
              )}
            </div>
            {/* <div className="col-12 col-md-4">
              <label style={{ fontSize: "14px" }} className="col-form-label">
                {constLabel?.lbl_file_name
                  ? constLabel.lbl_file_name
                  : "Filename"}
              </label>
              <input
                className={
                  errors.fileName
                    ? "form-control form-control-sm is-invalid"
                    : "form-control form-control-sm"
                }
                type="text"
                {...register("fileName")}
                placeholder="Enter filename"
              />
              {errors.fileName && (
                <span className="text-danger err-msg">
                  {errors.fileName.message}
                </span>
              )}
            </div> */}

            <div className="col-12 col-md-3">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label invisible d-block"
              >
                {constLabel?.lbl_file_name
                  ? constLabel.lbl_file_name
                  : "Filename"}
              </label>

              <button
                className="btn btn-sm btn-yellow px-3"
                onClick={handleSubmit(onsubmit)}
              >
                {constLabel?.lbl_submit ? constLabel.lbl_submit : "Submit"}
              </button>
            </div>
          </div>

          <div className="row mt-4 drawing_gallery row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
            {props.drawingFiles.map((val, i) => {
              const fileType = val.file?.type || ""; // Get the file type
              const fileURL = URL.createObjectURL(val.file); // Create object URL for the file

              return (
                <div className="col text-center" key={i}>
                  <div className="file-container">
                    {fileType.includes("image") ? (
                        <a href={fileURL} target="_blank" rel="noopener noreferrer">
                      <img
                        src={fileURL}
                        className="img-fluid"
                        alt={val.file?.name || "Image"}
                      />
                      </a>
                    ) : fileType.includes("audio") ? (
                      <a href={fileURL} target="_blank" rel="noopener noreferrer">
                        <img 
                          src={audio} 
                          alt="Audio File" 
                          className="audio-icon" 
                          style={{ width: "100px", height: "50px" }} 
                        />
                      </a>
                    ) : (
                      <div
                        onClick={() => window.open(fileURL, "_blank")}
                        className="other_doc d-flex justify-content-center align-items-center cursor-pointer"
                      >
                        <FontAwesomeIcon
                          className="icon-file-default"
                          style={{ color: "#fff" }}
                          icon={faFileAlt} // Icon for default file type
                          size="3x"
                        />
                      </div>
                    )}

                    <FontAwesomeIcon
                      onClick={() => removeFile(i)}
                      className="icon-close"
                      style={{ color: "#000" }}
                      icon={faClose}
                      size="lg"
                    />
                  </div>

                  <span className="file-name cursor-pointer">
                    {val.file?.name}
                  </span>
                </div>
              );
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-grey px-4"
            onClick={() => props.setGalVisible(false)}
          >
            {constLabel?.lbl_close ? constLabel.lbl_close : "Close"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default SOImageGallary;
